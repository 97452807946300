<template>
  <code-embed class="!p-4" v-bind="{ src, themes }" v-slot="{ src }">
    <a v-bind="{ href, target }">
      <img v-bind="{ src, ...$attrs }">
    </a>
  </code-embed>

  <img
    v-for="theme in themes.slice(1)"
    class="hidden"
    :key="theme"
    :src="`${src}?theme=${theme}`"
  >
</template>

<script setup>
import CodeEmbed from "@/components/CodeEmbed.vue"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  href: {
    type: String,
    required: true,
  },
  target: {
    type: String,
    default: "_blank",
  },
  src: {
    type: String,
    required: true,
  },
})

const themes = ["light", "neutral", "dark"]
</script>
