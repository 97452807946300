<template>
  <div
    ref="modals"
    @keydown.left="previous"
    @keydown.right="next"
    @touchstart="touchStarted"
    @touchmove="touchMoved"
    @touchend="touchEnded"
  >
    <modal
      v-for="(image, index) in images"
      :key="image"
      :open="index === selected"
      @open="selected = index"
      @close="selected = null"
      @next="next"
      @previous="previous"
    >
      <template #activator="{ openModal }">
        <a :href="image" class="flex w-24 h-16 overflow-hidden rounded" @click.prevent="openModal">
          <img
            class="min-w-full min-h-full object-center object-cover"
            loading="lazy"
            :src="image"
          >
        </a>
      </template>

      <button
        v-if="selected > 0"
        aria-label="Previous photo"
        class="absolute top-0 left-0 flex h-full px-1 lg:px-3 items-center justify-center ring-inset ring-white text-2xl lg:text-4xl"
        @click.stop="previous"
      >
        <icon name="chevron-left"/>
      </button>

      <img :src="image" class="max-h-screen lg:max-h-[calc(100vh-8rem)] shadow-2xl">

      <div class="absolute bottom-0 left-0 flex font-semibold w-full h-16 items-center justify-center">
        {{ selected + 1 }} of {{ images.length }}
      </div>

      <button
        v-if="selected < images.length - 1"
        aria-label="Next photo"
        class="absolute top-0 right-0 flex h-full px-1 lg:px-3 items-center justify-center ring-inset ring-white text-2xl lg:text-4xl"
        @click.stop="next"
      >
        <icon name="chevron-right"/>
      </button>
    </modal>
  </div>
</template>

<script setup>
import Icon from "@/components/Icon.vue"
import Modal from "@/components/Modal.vue"
import { ref } from "vue"

const modals = ref(null)
const selected = ref(null)
const touchStart = ref(null)
const touchEnd = ref(null)

const props = defineProps({
  images: Array,
})

const open = () => {
  return !!document.querySelector(":has([role='dialog']:not([aria-hidden='true'])")
}

const next = () => {
  if (!open()) {
    return
  }

  if (selected.value < props.images.length - 1) {
    selected.value = selected.value + 1
  }
}

const previous = () => {
  if (!open()) {
    return
  }

  if (selected.value > 0) {
    selected.value = selected.value - 1
  }
}

const touchStarted = (e) => {
  if (!open()) {
    return
  }
  touchStart.value = e.touches[0].clientX
}

const touchMoved = (e) => {
  if (!open()) {
    return
  }
  touchEnd.value = e.touches[0].clientX
}

const touchEnded = () => {
  if (!open()) {
    return
  }

  if (!touchStart.value || !touchEnd.value) {
    return
  }

  const diff = touchStart.value - touchEnd.value
  const minSwipeDistance = 50

  if (Math.abs(diff) < minSwipeDistance) {
    return
  }

  if (diff > 0) {
    next()
  } else {
    previous()
  }

  touchStart.value = null
  touchEnd.value = null
}
</script>
