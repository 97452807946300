<template>
  <div class="grid gap-2">
    <div v-if="themes.length" class="flex items-center justify-center">
      <div class="flex bg-slate-200 border border-slate-300 font-semibold items-center justify-center gap-1 p-1 rounded-full">
        <label
          v-for="option in themes"
          :key="option"
          :class="theme === option ? 'bg-sky text-white' : 'text-body'"
          class="border m-0 px-2 py-1 text-sm cursor-pointer rounded-full"
        >
          <input
            v-model="theme"
            :name="uniqueName"
            class="sr-only"
            type="radio"
            :value="option"
          >

          {{ option.charAt(0).toUpperCase() + option.slice(1) }}
        </label>
      </div>
    </div>

    <div>
      <div ref="embed" class="flex items-center justify-center bg-slate-200 border border-slate-300 rounded-t-md" v-bind="$attrs">
        <slot v-bind="{ src }"/>
      </div>

      <div class="flex items-center justify-center" @click="copy">
        <input v-model="code" class="border-x border-b w-full p-2 rounded-bl-md" readonly>

        <button class="bg-gray-400 border border-gray-400 font-semibold text-white p-2 rounded-br-md" type="button">
          Copy
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import useClipboard from "vue-clipboard3"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { uniqueId } from "lodash-es"

const { toClipboard } = useClipboard()

const code = ref("")
const embed = ref(null)
const theme = ref(null)
const uniqueName = uniqueId("theme-")

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  src: String,
  themes: {
    type: Array,
    default: () => [],
  },
})

onMounted(() => {
  theme.value = props.themes[0]

  updateCode()
})

watch(theme, () => {
  nextTick(() => {
    updateCode()
  })
})

const src = computed(() => {
  return theme.value === props.themes[0] ? props.src : `${props.src}?theme=${theme.value}`
})

const updateCode = () => {
  code.value = embed.value.innerHTML
}

const copy = () => {
  toClipboard(code.value).then(() => {
    toast.success("Code copied to clipboard")
  }, () => {
    toast.error("There was a problem copying the code to the clipboard")
  })
}
</script>
